import Navigo from 'navigo';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
// const feather = require('feather-icons');

// getElementById wrapper
function getId(id) {
    return document.getElementById(id);
}
// asyncrhonously fetch the html template partial from the file directory, then set its contents to the html of the parent element
function loadHTML(url, id) {
    // alert(url+' / '+id);
    let req = new XMLHttpRequest();
    req.open('GET', url);
    req.send();
    req.onload = () => {
        getId(id).innerHTML = req.responseText;
    };
}
function setPageAfterload (link) {
    // console.log(link);
    // Remove active state to all nav links then...
    $(".navbar a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
    });
    // Add active state to sidbar nav links
    const targetAnchors = document.body.querySelectorAll('[href="/' + link + '"].nav-link');
    targetAnchors.forEach(targetAnchor => {
        /*
        let parentNode = targetAnchor.parentNode;
        while (parentNode !== null && parentNode !== document.documentElement) {
            if (parentNode.classList.contains('collapse')) {
                parentNode.classList.add('show');
                const parentNavLink = document.body.querySelector(
                    '[data-bs-target="#' + parentNode.id + '"]'
                );
                parentNavLink.classList.remove('collapsed');
                parentNavLink.classList.add('active');
            }
            parentNode = parentNode.parentNode;
        }
        */
        targetAnchor.classList.add('active');
    });
    if(link=='index.html') $('.nav-link-def-route').addClass('active');
    // Now doing things like binding UI Actcions for this page...
    // Activate Feather icons
    // feather.replace();
    // Display .adminOnly, .accountantOnly... Objects
    switch ($.localStorage.getItem('type')) {
        case 'MyBelovedLord':
            $(".adminOnly").show();
            $(".adminOnlyFlex").css('display', 'flex');
            $(".adminHide").hide();
        break;
        case 'manager':
            $(".managerOnly").show();
            $(".managerOnlyFlex").css('display', 'flex');
            $(".managerHide").hide();
        break;
        case 'user':
            $(".userOnly").show();
            $(".userOnlyFlex").css('display', 'flex');
            $(".userHide").hide();
        break;
    }
    $.sessionStorage.setItem('myPage', link); // Change current page to session
    $(".profile-links").attr("href", "/users/"+$.localStorage.getItem('id'));
    // Smooth Scroll to div...
    App.refreshSmoothScroll();
    // Add the following code if you want the name of the file appear on select
    $(".custom-file-input").on("change", function(e) {
        //const fileName = $(this).val().split("\\").pop();
        let fileName = "";
        //e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
        //alert(e.target.files[0].name);
        for (var i = 0; i < e.target.files.length; i++) {
            fileName += '"'+e.target.files[i].name+'" ';
        }
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
    // Is it Mobile device
    const isMobile = (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<768) ? true : false;
}
function destroyDataTables() {
    // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
    $('.dataTable').DataTable().destroy(true);
}
const myDomain = window.location.origin; // https://my.domain
const rootUrl = (myDomain.indexOf('localhost')!==-1) ? '/' : '/'; // if online version is in a subfolder
const router = new Navigo(rootUrl); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
// use #! to hash
// const router = new Navigo(null, true, '#!'); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
router.hooks({
    // Ensure new data-navigo links work.
    after: function() {
        router.updatePageLinks();
    },
});
const routerContainer = document.getElementById('routerContainer');
const routerContainerExist = (routerContainer) ? true : false;
if(!routerContainerExist) document.location.href = './';
// Direct To Some Page...
// const goTo = App.urlParam('goto', document.URL); // Where are we going
// if(goTo == 'page') router.navigate("/page");

router.on({
    // 'routerContainer' is the id of the div element inside which we render the HTML
    '/': () => {
        destroyDataTables(); // In case user gets back home to go to an other table page next
        fetch('/home.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            // App.dashActions();
            setPageAfterload('index.html');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/admin': () => {
        destroyDataTables();
        fetch('/admin.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setAdminPage();
            setPageAfterload('admin');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/delivery': () => {
        destroyDataTables();
        fetch('/delivery.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setDeliveryPage();
            setPageAfterload('delivery');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/follow': () => {
        destroyDataTables();
        fetch('/follow.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setDeliveryPage();
            setPageAfterload('follow');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/order': () => {
        fetch('/lingerie.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setOrderPage('lingerie');
            setPageAfterload('order');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/shoe': () => {
        fetch('/shoe.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setOrderPage('shoe');
            setPageAfterload('shoe');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/accessories': () => {
        fetch('/accessories.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setOrderPage('accessories');
            setPageAfterload('accessories');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/login': () => {
        fetch('/login.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            // App.setLoginPage();
            setPageAfterload('login');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/users': () => {
        destroyDataTables();
        fetch('/clients.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setUsersListPage();
            setPageAfterload('users');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/users/:id': ({data}) => {
        destroyDataTables();
        fetch('/client.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setUserPage(data.id);
            setPageAfterload('users');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/dc': () => {
        App.logMeOut();
        // loadHTML('/logout.html', 'routerContainer');
    },
});

router.on(() => {
    fetch('/home.html').then(function (response) {
        return response.text(); // To fetch html below we fetch json as we usually do...
    }).then(function (html) {
        routerContainer.innerHTML = html;
        // App.dashActions();
        setPageAfterload('index.html');
        router.updatePageLinks(); // In case there are data-navigo links on the loaded page
    }).catch(function (err) {
        console.warn('Something went wrong.', err);
    });
});

// set the 404 route
router.notFound((query) => {
    loadHTML('/404.html', 'routerContainer');
    setPageAfterload('404');
    console.warn('Route not found');
    console.warn(query);
});

router.resolve();
/*
fetch('http://example.org').then(function(response) {
    if (response.ok) {
        return response.json()
    } else {
        throw ("Error " + response.status);
    }
}).then(function(data) {
    // do what you have to do with data
}).catch(function(err) {
    console.log(err);
});
*/